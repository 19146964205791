.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.create-calendar-btn {
  margin-left: 16px;
}

@media screen and (max-width: 430px) {
  .css-1xvinid {
    font-size: 1.7rem;
  }
  .css-1anx036 {
    font-size: 1rem;
  }
  .create-calendar-btn {
    margin-top: 10px;
    margin-left: 0px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginAlign {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media query for mobile devices with a maximum width of 600px */
@media (max-width: 600px) {
  .loginAlign {
    width: 100%;
  }
}

.loginCard Typography {
  font-size: 20px; /* Adjust the value as needed */
}
