.booking-form {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
  padding: 20px 40px 20px 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .booking-form {
    /* Modify the styles for mobile devices */
    width: 95%;
  }
}

.confirmation {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 599px) {
  .booking-form {
    padding: 5px;
  }
}

.box-form {
  margin: auto;
}

@media screen and (min-width: 600px) {
  .box-form {
    width: 50ch;
  }
}

@media screen and (max-width: 599px) {
  .box-form {
    width: 100%;
  }
}
