.colorPicker {
  position: absolute;
  top: 5px;
  left: 0px;
  padding-top: 0;
}

.rounded-box {
  border-radius: 10%;
}
