.setup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.stepper-body {
  margin: auto;
  width: 70%;
  padding: 3%;
}

.MuiStepLabel-labelContainer span {
  font-size: 17px;
}

.stepper-header {
  margin-bottom: 35px;
}

.brandImageSection {
  margin-top: 10px;
}

.brandImageHeader {
  margin-top: 0px;
  width: 50px;
  height: auto;
}
