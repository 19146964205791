@media (min-width: 768px) {
  .settings-form {
    border: 1px solid #ccc;
    padding: 20px 40px 20px 40px;
    border-radius: 8px;
    margin-top: 60px;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
  }
}

.setting-field {
}

.hour-field {
  width: 100%;
}

.centerContent-set {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .card-set {
    border: 1px solid #ccc;
    padding: 20px 40px 20px 40px;
    border-radius: 8px;
    margin-top: 20px;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
    width: 39vw;
  }
}

@media (max-width: 768px) {
  .card-set {
    border: 1px solid #ccc;
    padding: 20px 40px 20px 40px;
    border-radius: 8px;
    margin-top: 20px;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
    width: 100%;
  }
}

.card-content {
  padding: 8px;
}

.duration-before-select {
  margin-top: 20px;
}

.duration-select {
  margin-top: 20px;
}

.base-slug {
  background-color: #eeeeee;
  width: 50%;
}

.slug-field {
  width: 50%;
}

.shared-cal-button {
  display: flex;
  justify-content: center;
}

.image-upload-field {
  width: 60%;
}

.calendarImageUpload {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
