@media (min-width: 768px) {
  .calendar-card {
    border: 1px solid #ccc;
    padding: 20px 30px 20px 30px;
    border-radius: 8px;
    display: inline-block;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* spaces between rows in calendar */
.css-mvmu1r {
  margin: 8px;
}

/* height of calendar*/
.css-2ldko6 {
  min-height: 280px;
}

.label-styling {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -10px;
}

.custom-static-datepicker {
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  height: 100%;
}

.custom-static-datepicker .MuiDayCalendar-weekContainer {
  margin-bottom: 8px;
  font-size: 16px;
  height: 100%;
}

.custom-static-datepicker .MuiDayCalendar-slideTransition {
  min-height: 280px;
}

.custom-static-datepicker .MuiPickersDay-root {
  font-size: 1rem;
}

.custom-static-datepicker .MuiDayCalendar-weekDayLabel {
  font-size: 1rem;
}

.custom-static-datepicker .MuiButtonBase-root {
  font-size: 1rem;
}

.custom-static-datepicker .MuiSvgIcon-root {
  font-size: 1.5rem;
}
