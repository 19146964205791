.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  maxwidth: 750;
  margin-top: 50px;
  margin-left: 240px;
}

.bookingCard {
  min-width: 600px;
  margin: 10px;
  color: light-gray;
  position: relative;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.bookingContent {
  margin-top: 2px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.cardEmail {
  margin-top: -10px;
}
