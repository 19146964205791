.profileTextField,
.profileText {
  width: 400px;
}

.accordionText {
  margin-top: 0;
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (min-width: 768px) {
  .centerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 240px;
  }
}

@media (max-width: 768px) {
  .centerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 0px;
  }
}

.optionLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.card {
  margin-top: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.brandImage {
  width: auto;
  max-width: 400px;
}

.centerAlign {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}
